import React, {useState} from 'react';
import {Button, ButtonPriority, StatesButton} from 'wix-ui-tpa';
import {classes} from './CheckoutButton.st.css';
import classnames from 'classnames';
import {useControllerProps} from '../../../domain/controllers/ControllerContext';
import {StatesButtonStates, ThreeDotsLoader} from 'wix-ui-tpa/cssVars';

export enum CheckoutButtonDataHook {
  button = 'CheckoutButtonDataHook.button',
  threeDotsLoader = 'CheckoutButtonDataHook.threeDotsLoader',
}

export interface CheckoutButtonProps {
  disabled?: boolean;
  priority?: ButtonPriority;
  text: string;
}

export function CheckoutButton({disabled, priority, text}: CheckoutButtonProps) {
  const accessibilityEnabled = useControllerProps().host.accessibilityEnabled;
  const {navigateToCheckout, shouldUseThreeDotsLoaderOnCheckoutButton} = useControllerProps().navigationStore;

  const [checkoutButtonState, setCheckoutButtonState] = useState(StatesButtonStates.IDLE);

  return shouldUseThreeDotsLoaderOnCheckoutButton ? (
    <StatesButton
      priority={priority}
      className={classnames(classes.checkoutButton)}
      data-hook={CheckoutButtonDataHook.button}
      data-wix-checkout-button={CheckoutButtonDataHook.button}
      disabled={disabled}
      state={checkoutButtonState}
      onClick={() => {
        setCheckoutButtonState(StatesButtonStates.IN_PROGRESS);
        return navigateToCheckout({accessibilityEnabled});
      }}
      inProgressContent={
        <ThreeDotsLoader data-hook={CheckoutButtonDataHook.threeDotsLoader} className={classes.threeDotButton} />
      }
      idleContent={text}
      upgrade
    />
  ) : (
    <Button
      priority={priority}
      className={classnames(classes.checkoutButton, classes.checkoutButtonOld)}
      data-hook={CheckoutButtonDataHook.button}
      data-wix-checkout-button={CheckoutButtonDataHook.button}
      disabled={disabled}
      onClick={() => navigateToCheckout({accessibilityEnabled})}
      upgrade>
      <span className={classes.checkoutButtonLabel}>{text}</span>
    </Button>
  );
}
